import * as _ from 'lodash';
import * as moment from 'moment-timezone';

const NCAA_ID = 101;

export const PLAYER_POSITION_MAP = {
  1: 'PG',
  2: 'SG',
  3: 'SF',
  4: 'PF',
  5: 'C',
};

export const maxDict = {
  // Measures
  'mScore': 15,
  'possessionsPerGame': 70,
  'minutesPerGameField': 30,
  'shotAttemptsPerGame': 20,
  'pointsPerAttempt': 1.3,
  'FG%': 0.6,
  '3P%': 0.4,
  'USG': 0.3,
  'FTA/100': 10,
  'AST%': 0.30,
  'TO%': 0.20,
  'ORB%': 0.12,
  'DRB%': 0.25,
  'STL%': 0.03,
  'BLK%': 0.05,
  'PF%': 0.06,
  // Metrics v2 Measures
  'OFDPer100': 1,
  'DunksPer100': 5,
  'SFD%': 0.16,
  'SA USG': 0.25,
  'TO USG': 0.04,
  'qPPA': 1.33,
  'xPPA': 1.28,
  'C&S 3S TAKE%': 0.9,
  'POT AST%': 0.23,
  'POT AST PPA': 1.35,
  'POT AST qPPA': 1.28,
  'POT AST xPPA': 1.28,
  'CONT%': 0.33,
  'DP CONT%': 0.46,
  'DP CONT PPA': 1.45,
  'DP CONT qPPA': 1.42,
  'DP CONT xPPA': 1.42,
  // Box Score (Per Game)
  'PTSPerGame': 25,
  '2PAPerGame': 12,
  '3PAPerGame': 8,
  'FTAPerGame': 6,
  'FT%': 0.9,
  'TRBPerGame': 10,
  'ASTPerGame': 7,
  'STLPerGame': 2,
  'TOPerGame': 3,
  'BLKPerGame': 2,
  'PFPerGame': 3,
  'SFDPerGame': 3,
  'POTASTPerGame': 10,
  'OFDPerGame': 0.5,
  'CONTPerGame': 17,
  'DPCONTPerGame': 7,
  // Box Score (Totals)
  'MinTotal': 2500,
  'PointsTotal': 1500,
  '2PATotal': 750,
  '3PATotal': 500,
  'FTATotal': 350,
  'REBTotal': 600,
  'ASTTotal': 400,
  'STLTotal': 90,
  'TOTotal': 180,
  'BLKTotal': 80,
  'PFTotal': 200,
  'SFDTotal': 160,
  'POTASTTotal': 700,
  'OFDTotal': 30,
  'CONTTotal': 1200,
  'DPCONTTotal': 500,
  'gamesPlayed': 82,
  // Shooting
  'ALL_ATT': 1250,
  'HALFCOURT_ATT': 1000,
  'TRANSITION_ATT': 170,
  'PUTBACK_ATT': 100,
  'CS_DIST': 0.8,
  'TENDENCY': 0.4,
  'ZONE_ATT': 300,
  'ZONE_DIST': 0.4,
  'GS_DIST': 0.4,
  // Below fields are used when changing from PPA in the shots page filter
  'efg': 0.6,
  'fgPercent': 0.6,
  'qefg': 0.6,
  'xefg': 0.6,
  'qfg': 0.6,
  'xfg': 0.6,
  'qefgRes': 7,
  'qfgRes': 7,
  'xefgRes': 7,
  'xfgRes': 7,
  // Modeled Shooting
  'QRES': 7,
  'COEF_NOW': 7,
  'COEF_BULL': 7,
  'FT_COEF': 10,
  // Playing Time
  'GS': 60,
  'GP': 60,
  'POSITION': 0.8,
  'DEFENDED/DEFENDING': 0.4,
  'OBSERVERED_f': 0.05,
  'OBSERVERED_c': 0.05,
  'OBSERVERED_ts': 0.1,
  'OBSERVERED_s': 0.1,
  'OBSERVERED_kr': 0.35,
  'OBSERVERED_reAggregate': 0.5,
  'EXPECTED_f': 0.05,
  'EXPECTED_c': 0.05,
  'EXPECTED_ts': 0.1,
  'EXPECTED_s': 0.35,
  'EXPECTED_kr': 0.5,
  'EXPECTED_reAggregate': 0.5,
  'DEFEDNING_AVG': 5,
  'RESIDUAL_f': 0.05,
  'RESIDUAL_c': 0.1,
  'RESIDUAL_ts': 0.1,
  'RESIDUAL_s': 0.1,
  'RESIDUAL_kr': 0.1,
  'RESIDUAL_reAggregate': 0.15,
  'RESIDUAL_AVG': 0.7,
  //  Charting
  'ALL_CAT_ATT': 1500,
  'INDV_CAT_ATT': 1000,
};

export const allPositions = ['PG', 'SG', 'SF', 'PF', 'C'];

const stringFields = ['entityName', 'entityPosition', 'entityTeam', 'name', 'startingPoint', 'playcall'];

export const paginateResults = (results: any[], maxResults: number, pageIndex: number) => {
  let paginatedResults = _.cloneDeep(results);
  pageIndex = pageIndex == 0 ? 1 : pageIndex;
  const potentialLastElement = pageIndex * 25;
  if (potentialLastElement > maxResults) {
    paginatedResults = paginatedResults.slice(potentialLastElement - 25, maxResults);
  } else {
    paginatedResults = paginatedResults.slice(potentialLastElement - 25, potentialLastElement);
  }
  return paginatedResults;
};

const defaultSort = (isNCAA, isComparison, row) => {
  if (isComparison) {
    return -1 * row['season'];
  } else if (isNCAA && (row['minutesPerGame'] != null || row['attempts'] != null)) {
    return -1 * (row['minutesPerGame'] ? row['minutesPerGame'] : row['attempts']);
  } else if (row['mScore'] != null) {
    return -1 * row['mScore'];
  } else if (row['AllPGattempts'] != null) {
    return -1 * row['AllPGattempts'];
  } else if (_.get(row, 'shots.states')) {
    return -1 * row['shots']['states']['HALFCOURT']['ALL']['ALL'].shotAttempts;
  } else if (row['ALLHALFCOURTshotAttemptsOffense'] || row['ALLHALFCOURTshotAttemptsDefense']) {
    return -1 * (row['ALLHALFCOURTshotAttemptsOffense'] || row['ALLHALFCOURTshotAttemptsDefense']);
  } else {
    return -3 * (row.gamesStarted || 0) - 2 * (row.gamesPlayed || 0) - (row.minutesPerAvailableGame || 0);
  }
};

const determineKey = (item, sortBy, sortAsc) => {
  // Coeffecient used for numbers
  const coeffecient = sortAsc === 'asc' ? 1 : -1;
  let returnValue;

  if (!sortBy || typeof sortBy !== 'string') {
    return 99999; // Return a default value for invalid sortBy
  }

  switch (sortBy) {
    case 'entityName':
      returnValue = item.entity.name;
      break;
    case 'entityPosition':
    case 'position':
      returnValue = item.entity.position;
      break;
    case 'entityAge':
      returnValue = coeffecient * _.get(item, 'entity.exactAge');
      break;
    case 'entityTeam':
      returnValue = _.get(item, 'entity.affiliation.abbrev');
      break;
    case 'name':
      returnValue = _.get(item, 'entityName');
      break;
    case 'positions':
      returnValue = coeffecient * _.get(item, 'primaryPosition');
      break;
    case 'startingPoint':
    case 'playcall':
      returnValue = _.get(item, sortBy);
      break;
    case 'agility':
    case 'reach':
    case 'sprint':
    case 'vertNoStep':
    case 'vertReachMax':
    case 'wingspan':
      returnValue = _.get(item, sortBy);
      returnValue = coeffecient * _.get(returnValue, 'measurement');
      break;
    default:
      if (['ALL', 'HALFCOURT', 'TRANSITION', 'PUTBACK'].includes(sortBy.split('.')[0])) {
        let rawValue = _.get(item, 'shots.states.' + sortBy);
        if (rawValue == null) {
          rawValue = _.get(item, sortBy);
        }
        if (rawValue != null) {
          const floatValue = parseFloat(rawValue);
          returnValue = floatValue ? coeffecient * floatValue : coeffecient * floatValue;
        } else {
          returnValue = null;
        }
      } else {
        // Remove data formatters used in filtering
        const accessor = sortBy.split(':')[0];
        returnValue = item[accessor] != null ? coeffecient * item[accessor] : null;
      }
  }

  // We always want null values to be at the bottom of the table
  return returnValue == null ? 99999 : returnValue;
};

export const sortResults = (results: any, sortBy: string, sortAsc: string, isNCAA?: boolean, isComparison?: boolean) => {
  let sortedResults = _.cloneDeep(results);
  if (!sortBy || !sortAsc) {
    sortedResults = _.sortBy(sortedResults, defaultSort.bind(null, isNCAA, isComparison));
  } else {
    sortedResults = _.orderBy(sortedResults, (row) => {
      return determineKey(row, sortBy, sortAsc);
    });
    if (sortedResults?.length && sortedResults[0].parentRow == true) {
      sortedResults.forEach((row) => {
        row.childRows = _.orderBy(row.childRows, (childRow) => {
          return determineKey(childRow, sortBy, sortAsc);
        });
      })
    }
  }

  if (sortAsc === 'desc' && stringFields.includes(sortBy)) {
    sortedResults.reverse();
    if (sortedResults[0].parentRow == true) {
      sortedResults.forEach((row) => {
        row.childRows.reverse();
      });
    }
  }

  return sortedResults;
};

export const sortMostRecentSeason = (results: any, sortBy: string, sortAsc: string) => {
  let sortedResults = _.cloneDeep(results);
  const playerMap = new Map();
  for (let item of sortedResults) {
    if (!playerMap.has(item.entityID)) {
      playerMap.set(item.entityID, []);
    }
    playerMap.get(item.entityID).push(item);
  }

  let mostRecentSeasons = [];
  let otherSeasons = [];

  for (let [entityID, seasons] of playerMap) {
    seasons.sort((a, b) => b.season - a.season);
    const mostRecentSeason = seasons.shift();
    mostRecentSeasons.push(mostRecentSeason);
    otherSeasons = otherSeasons.concat(seasons);
  }

  if (!sortBy || !sortAsc) {
    mostRecentSeasons = _.sortBy(mostRecentSeasons, defaultSort.bind(null, false, false));
  } else {
    mostRecentSeasons = _.orderBy(mostRecentSeasons, (row) => {
      return determineKey(row, sortBy, sortAsc);
    });

    if (mostRecentSeasons[0]?.parentRow) {
      mostRecentSeasons.forEach((row) => {
        row.childRows = _.orderBy(row.childRows, (childRow) => {
          return determineKey(childRow, sortBy, sortAsc);
        });
      });
    }
  }

  if (sortAsc === 'desc' && stringFields.includes(sortBy)) {
    mostRecentSeasons.reverse();
    if (mostRecentSeasons[0]?.parentRow) {
      mostRecentSeasons.forEach((row) => {
        row.childRows.reverse();
      });
    }
  }

  sortedResults = mostRecentSeasons.concat(otherSeasons);

  return sortedResults;
};

export const filterColumn = (values, filteredValues, key) => {
  let isNestedField;
  let operation;
  let operand;

  // Shots fields
  if (key.includes('.')) {
    isNestedField = true;
    filteredValues = _.filter(filteredValues, (data) => {
      if (['ALL', 'HALFCOURT', 'TRANSITION', 'PUTBACK'].includes(key.split('.')[0])) {
        const rawValue = _.get(data, 'shots.states.' + key);
        let floatValue = parseFloat(rawValue);
        floatValue = key.includes('dist') ? floatValue / 100 : floatValue;
        return (values.minValue == null || floatValue >= values.minValue) &&
          (values.maxValue == null || floatValue <= values.maxValue);
      }
    });
  }
  // Checkboxes
  if (values?.checkValues?.length) {
    filteredValues = _.filter(filteredValues, (data) => {
      // This applies for slider
      return data[key] && values.checkValues.includes(data[key]) ||
        data?.entity && data?.entity[key] && values.checkValues.includes(PLAYER_POSITION_MAP[data.entity[key]]);
    });
  }
  // Dates
  if (values?.minDate) {
    filteredValues = _.filter(filteredValues, (data) => {
      return moment(data['postDatetime']) >= values.minDate;
    });
  }
  if (values?.maxDate) {
    filteredValues = _.filter(filteredValues, (data) => {
      return moment(data['postDatetime']) <= values.maxDate;
    });
  }
  if (key.includes(':')) {
    // Used for value formatting (for instance multiplying by 100)
    const splitString = key.split(':');
    key = splitString[0];
    operation = splitString[1];
    operand = splitString[2];
  }
  if (values?.minValue != null && !isNestedField) {
    filteredValues = _.filter(filteredValues, (data) => {
      if (operation && operand && !isNestedField) {
        return data[key] && (eval(`${data[key]} ${operation} ${operand}`) >= values.minValue);
      } else if (!isNestedField) {
        return data[key] && data[key] >= values.minValue;
      }
    });
  }
  if (values?.maxValue != null && !isNestedField) {
    filteredValues = _.filter(filteredValues, (data) => {
      if (operation && operand && !isNestedField) {
        return data[key] && (eval(`${data[key]} ${operation} ${operand}`) <= values.maxValue);
      } else if (!isNestedField) {
        return data[key] && data[key] <= values.maxValue;
      }
    });
  }
  return filteredValues;
};

export const searchResults = (results: any, filterText: string) => {
  if (filterText) {
    results.rows = _.filter(results.rows, (row) => {
      return row.entity.name?.toLowerCase().includes(filterText) || row.entity.affiliation?.name?.toLowerCase().includes(filterText);
    });
    if (results.gameMinutes) {
      results.gameMinutes = _.filter(results.rows, (row) => {
        return row.entity.name?.toLowerCase().includes(filterText) || row.entity.affiliation?.name?.toLowerCase().includes(filterText);
      });
    }
  }
  results.maxResults = results.rows?.length;
  return results;
};

export const filterResults = (results: any, filterProperties: any) => {
  if (!filterProperties) {
    return results;
  }
  const filteredResults = _.cloneDeep(results);
  Object.keys(filterProperties).forEach((columnName) => {
    if (filterProperties[columnName]) {
      filteredResults.rows = filterColumn(filterProperties[columnName], filteredResults.rows, columnName);
      if (filteredResults.gameMinutes) {
        filteredResults.gameMinutes = filterColumn(filterProperties[columnName], filteredResults.gameMinutes, columnName);
      }
    }
  });
  filteredResults.maxResults = filteredResults.rows?.length;
  return filteredResults;
};
